@import "../../../GlobalStyles/mixin";

.wrapper {
  margin-top: 64px;

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;

    h4 {
      font-size: 3rem;
      text-transform: uppercase;

      i {
        font-size: 2.8rem;
        margin-right: 8px;

        @media (max-width: 768px) {
            font-size: 1.8rem;
        }
      }

      @media (max-width: 768px) {
        font-size: 1.8rem;
      }
    }

    span {
        font-size: 2rem;
        font-weight: 600;
    }

    button, a {
        padding: 8px 16px;
        height: 40px;
        font-weight: 600;
        font-size: 1.8rem;
        display: flex;
        align-items: center;
        justify-content: center;
    
        @media (max-width: 768px) {
          font-size: 1.6rem;
          padding: 4px 8px;
          margin-left: 12px;
          flex-shrink: 0;
        }
    }

    .see-more {
      @include borderBtn(var(--primary-color));
    }

    .delete-all {
        @include outlineBtn(var(--red-color))
    }
  }

  .list {
    display: flex;
    margin: 0 -8px;
    flex-wrap: wrap;
    gap: 24px 0;
  }
}
