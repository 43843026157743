@import "../../../GlobalStyles/mixin";

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  padding: 0 var(--padding-desktops);
  height: 60px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1);
  background-color: inherit;
  color: inherit;
  z-index: 999;

  @media (max-width: 1023px) {
    padding: 0 var(--padding-table);
  }

  @media (max-width: 768px) {
    padding: 0 var(--padding-mobile);
  }

  .wrapper-left {
    display: flex;
    gap: 32px;
    align-items: center;

    @media (max-width: 1399px) {
      gap: 16px;
    }

    .bars {
      font-size: 2.2rem;
      padding: 16px;
      background-color: transparent;
      margin-left: -16px;
      color: inherit;
    }

    .logo {
      display: flex;
      gap: 12px;
      align-items: center;

      a {
        flex-shrink: 0;
        width: 40px;
        height: 40px;
        border-radius: 8px;
        overflow: hidden;

        @media (max-width: 1023px) {
          display: none;
        }
      }

      h4 {
        font-size: 2.6rem;
        font-weight: 900;
        color: var(--primary-color);

        @media (max-width: 1399px) {
          display: none;
        }

        @media (max-width: 1023px) {
          display: block;
          font-size: 2.2rem;
        }

        @media (max-width: 768px) {
          font-size: 2rem;
        }
      }
    }

    .list {
      display: flex;
      gap: 12px;

      .item {
        color: inherit;
        font-weight: 600;
        cursor: pointer;
        position: relative;
        border-radius: var(--border-radius);

        > a,
        > div {
          border-radius: var(--border-radius);
          display: inline-block;
          padding: 4px 12px;
          transition: 0.3s;
          user-select: none;

          i {
            margin-right: 6px;
            transition: transform 0.3s ease;
          }

          &:hover {
            color: var(--white-color);
            background-color: var(--primary-color);
          }
        }

        &:hover ul {
          opacity: 1;
          pointer-events: auto;
        }
      }

      .item:last-child:hover i {
        transform: rotate(-90deg);
      }

      .item.active {
        background-color: var(--primary-color);
        color: var(--white-color);
      }
    }
  }

  .wrapper-right {
    display: flex;
    align-items: center;

    a {
        display: block;
        position: relative;
    }
    
    .history,
    .save,
    .theme {
      display: block;
      font-size: 2.2rem;
      font-weight: 600;
      transition: 0.3s;
      padding: 12px 16px;
      position: relative;
      cursor: pointer;

      &:hover {
        color: var(--primary-color);
      }
    }

    .theme {
        @include label("Tuỳ chỉnh giao diện", 100%);
    }

    .history {
      @include label("Lịch sử đã xem", 100%);
    }

    .save {
      @include label("Kho lưu trữ", 100%);
    }

    a.active,
    a.active {
      color: var(--primary-color);
    }

    .container-search {
      height: 40px;
      display: flex;
      align-items: center;
      padding: 8px 4px;
      border: 2px solid #e7e7e7;
      border-radius: var(--border-radius);
      transition: 0.3s;
      margin-left: 12px;

      input {
        border: none;
        outline: none;
        margin-left: 12px;
        font-size: 1.6rem;
        background-color: inherit;
        color: inherit;
      }

      a {
        padding: 0 8px;
        font-size: 1.6rem;
        transition: 0.3s;

        &:hover {
          color: var(--primary-color);
        }
      }

      &:focus-within {
        border-color: var(--primary-color);
      }
    }
  }
}
