@import "../../components/GlobalStyles/mixin";

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 12px;

  h1 {
    font-size: 10rem;
    color: var(--primary-color);
    margin-bottom: -12px;
  }

  h4 {
    font-size: 5rem;
    text-align: center;
  }

  p {
    text-align: center;
    font-weight: 600;
  }

  a {
    margin-top: 32px;
    padding: 8px 16px;
    @include outlineBtn(var(--primary-color));
  }
}
