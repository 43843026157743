@import "../../../GlobalStyles/mixin";

.wrapper.fadeOut {
  animation: fadeOut 0.5s ease;
}

.wrapper {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 9998;
  display: flex;
  justify-content: flex-end;
  animation: fadeIn 0.5s ease;

  .modal.slideOut {
    animation: slideOut 0.5s ease;
  }

  .modal {
    width: 50%;
    background-color: var(--white-color);
    padding: 0 32px 32px 32px;
    position: relative;
    max-height: 100vh;
    overflow-y: auto;
    overscroll-behavior-y: none;
    animation: slideIn 0.5s ease;

    @media (max-width: 1023px) {
      width: 70%;
    }

    @media (max-width: 768px) {
      width: 100%;
      border-radius: unset;
      padding: 0 var(--padding-mobile) var(--padding-mobile)
        var(--padding-mobile);
    }

    .close {
      position: fixed;
      top: 0;
      right: 0;
      font-size: 3rem;
      background-color: transparent;
      margin-left: auto;
      padding: 16px;
      transition: 0.3s;
      opacity: 0.6;

      &:hover {
        opacity: 1;
      }
    }

    .body {
      margin-top: 64px;

      .title {
        margin-bottom: 24px;

        h4 {
          font-size: 3rem;
          color: var(--black-color);
        }

        p {
          color: #666;
          font-style: italic;
          margin: 8px 0;
        }
      }

      textarea {
        padding: 16px;
        min-height: 160px;
        width: 100%;
        margin-bottom: 12px;
        border: 2px solid #ccc;
        border-radius: 10px;
        transition: 0.3s;
        font-size: 1.6rem;

        &:focus {
          border-color: var(--primary-color);
        }
      }
      .box {
        margin: 24px 0 64px 0;

        .ok {
          font-size: 1.8rem;
          display: flex;
          padding: 8px 16px;
          margin-left: auto;
          @include outlineBtn(var(--primary-color));
        }

        .ok.disabled {
          @include disabledBtn();
        }
      }

      .list {
        margin-top: 32px;
        display: flex;
        flex-direction: column;
        gap: 32px;
        height: 100%;

        .item {
          display: flex;
          gap: 16px;

          figure {
            flex-shrink: 0;
            border: unset;
            width: 50px;
            height: 50px;
            overflow: hidden;
            border-radius: 50%;
          }

          .item-body {
            width: 100%;
            height: 100%;
            padding: 12px;
            background-color: #f2f3f5;
            border-radius: 10px;

            .item-content {
              h5 {
                font-size: 2rem;
                color: var(--black-color);
              }

              p {
                color: var(--black-color);
                font-size: 1.6rem;
                word-break: break-word;
              }

              a {
                color: var(--primary-color);
                text-decoration: underline;
                cursor: pointer;
                font-weight: 600;

                &:not(:last-child) {
                  margin-right: 4px;
                }
              }
            }

            .item-actions {
              display: flex;
              justify-content: flex-end;
              align-items: center;
              gap: 12px;

              .delete,
              .edit,
              .cancel,
              .save {
                color: var(--primary-color);
                font-weight: 600;
                cursor: pointer;
                user-select: none;

                @media (max-width: 768px) {
                  font-size: 1.6rem;
                }

                &:hover {
                  text-decoration: underline;
                }
              }

              .dot {
                color: var(--black-color);
              }

              .time {
                color: #969696;
                font-size: 1.6rem;

                @media (max-width: 768px) {
                  font-size: 1.4rem;
                }
              }
            }
          }
        }
      }
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideOut {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(100%);
  }
}
