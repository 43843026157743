.wrapper {
  position: absolute;
  top: 100%;
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  width: 600px;
  background-color: var(--white-color);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  height: 60vh;
  overflow-y: auto;
  padding: 16px;
  border-radius: var(--border-radius);
  opacity: 0;
  pointer-events: none;
  transition: 0.3s;
  overscroll-behavior-y: none;

  @media (max-width: 1023px) {
    position: unset;
    width: 100%;
    display: block;
    height: 0;
    padding: 0;
    opacity: unset;
    pointer-events: unset;
    box-shadow: unset;
    border-radius: unset;
    margin-left: 12px;
  }

  .item {
    transition: 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: calc(33.33333% - 8px);
    border-radius: var(--border-radius);
    color: var(--black-color);
    user-select: none;

    &:hover {
      background-color: var(--primary-color);
      color: var(--white-color);
      border-radius: var(--border-radius);
    }
    @media (max-width: 1032px) {
      width: 100%;
      text-align: left;

      &:hover {
        color: var(--primary-color);
        background-color: unset;
      }
    }

    a {
      flex: 1;
      white-space: nowrap;
      font-size: 1.6rem;
      padding: 6px 8px;
    }
  }

  .item.active {
    color: var(--white-color);
    background-color: var(--primary-color);

    @media (max-width: 1023px) {
      color: var(--primary-color);
      font-weight: 600;
      background-color: unset;
    }
  }
}
