.wrapper {
    padding: 32px 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 12px;
    background-color: rgb(63, 63, 63);
    color: var(--white-color);
    margin-top: 64px;
    
    @media (max-width: 768px) {
        padding: 16px;
    }
    
    h4 {
        font-size: 4rem;
    }
    
    p {
        text-align: justify;
        font-size: 2rem;
    }

    .socials {
        display: flex;
        gap: 16px;
        font-size: 3rem;

        a {
            transition: .3s;
            color: inherit;

            &:hover {
                color: var(--primary-color);
            }
        }
    }

    span {
        font-weight: 600;
        font-size: 2rem;
    }
}