@import "../../../GlobalStyles/mixin";

.wrapper {
  flex-shrink: 0;
  width: 100%;
  height: 320px;
  display: flex;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  color: var(--white-color);
  background-color: #00000099;
  position: relative;

  @media (max-width: 1023px) {
    height: 500px;
  }

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    background-image: inherit;
    background-size: cover;
    background-position: center;
    z-index: 1;
    filter: blur(8px);

    @media (max-width: 768px) {
      content: unset;
    }
  }

  .inner {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 32px;
    position: absolute;
    z-index: 2;
    left: var(--pos-desktops);

    @media (max-width: 1023px) {
      left: var(--pos-table);
      top: var(--pos-table);
    }

    @media (max-width: 768px) {
      left: var(--pos-mobile);
      top: var(--pos-mobile);
    }

    figure {
      width: 180px;
      height: 250px;
      border-radius: var(--border-radius-img);
      overflow: hidden;

      @media (max-width: 768px) {
        display: none;
      }
    }

    .info {
      width: calc(100% - (var(--pos-desktops) * 2 + 180px + 32px));

      @media (max-width: 1023px) {
        width: calc(100% - (var(--pos-table) * 2 + 180px + 32px));
      }

      @media (max-width: 768px) {
        width: calc(100% - var(--pos-mobile) * 2);
      }

      h4 {
        font-size: 3rem;
        text-shadow: var(--text-shadow);
      }

      h2 {
        text-shadow: var(--text-shadow);
        color: var(--primary-color);
        font-size: 4rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        @media (max-width: 768px) {
          font-size: 2.2rem;
          overflow: unset;
          white-space: unset;
          text-overflow: unset;
        }
      }

      .categorys {
        display: flex;
        flex-wrap: wrap;
        gap: 12px;
        margin-top: 12px;

        a {
          display: block;
          font-size: 1.6rem;
          font-weight: 600;
          padding: 2px 8px;
          border-radius: var(--border-radius);
          border: 2px solid var(--white-color);
          background-color: transparent;
          transition: 0.3s;
          user-select: none;

          &:hover {
            color: var(--primary-color);
            border-color: var(--primary-color);
          }
        }
      }

      .actions {
        display: flex;
        gap: 12px;
        margin-top: 24px;

        @media (max-width: 768px) {
          margin-top: 12px;
        }

        i {
          margin-right: 8px;
        }

        .read-now,
        .detail {
          font-size: 2rem;
          padding: 4px 12px;
        }

        .read-now {
          @include outlineBtn(var(--primary-color));
        }

        .detail {
          @include outlineBtn(var(--white-color));
        }
      }
    }
  }
}
