.wrapper.fadeOut {
  animation: fadeOut 0.5s ease;
}

.wrapper {
  position: fixed;
  inset: 0;
  min-height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  animation: fadeIn 0.5s ease;

  .modal.slideOut {
    animation: slideOut 0.5s ease;
  }

  .modal {
    overflow-y: auto;
    overscroll-behavior-y: none;
    position: absolute;
    left: 0;
    width: 80%;
    height: 100%;
    padding: 16px;
    background-color: var(--white-color);
    animation: slideIn 0.5s ease;
    position: relative;

    .actions {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;

      .theme,
      .close {
        padding: 16px;
        font-size: 2.6rem;
        background-color: transparent;
      }
    }

    .container-search {
      margin-top: 64px;
      height: 40px;
      display: flex;
      align-items: center;
      padding: 8px 4px;
      border: 2px solid #e7e7e7;
      border-radius: var(--border-radius);
      transition: 0.3s;

      input {
        width: 100%;
        border: none;
        outline: none;
        margin-left: 12px;
        font-size: 1.6rem;
      }

      a {
        color: var(--black-color);
        padding: 0 8px;
        font-size: 1.6rem;
        transition: 0.3s;

        &:hover {
          color: var(--primary-color);
        }
      }

      &:focus-within {
        border-color: var(--primary-color);
      }
    }

    .list {
      margin-top: 12px;
      display: flex;
      flex-direction: column;

      .item {
        color: var(--black-color);

        a {
          padding: 4px 0;
          display: block;
          width: 100%;
        }

        i {
          margin-left: 8px;
        }
      }

      .item.active {
        color: var(--primary-color);
        font-weight: 600;
      }
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideOut {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(-100%);
  }
}
