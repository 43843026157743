@import "../../../GlobalStyles/mixin";

.wrapper {
  width: var(--width-comic-desktops);
  flex-shrink: 0;
  padding: 0 8px;
  transition: 0.3s;
  position: relative;
  overflow: hidden;

  @media (max-width: 1023px) {
    width: var(--width-comic-table);
  }

  @media (max-width: 768px) {
    width: var(--width-comic-mobile);
  }

  &:hover {
    transform: scale(1.06);
  }

  &:hover .info h4 {
    color: var(--primary-color);
  }

  figure {
    height: 260px;
    border-radius: var(--border-radius-img);
    overflow: hidden;
    transition: 0.3s;
  }

  .info {
    margin-top: 12px;

    h4 {
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      color: inherit;
      display: -webkit-box;
      font-size: 1.6rem;
      margin-top: 8px;
      overflow: hidden;
      text-overflow: ellipsis;
      transition: 0.3s;
    }
  }
}
