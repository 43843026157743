.wrapper {
    margin: 12px 0;
    h4 {
        color: var(--primary-color);
        font-size: 2rem;
        text-transform: uppercase;
        margin-bottom: 12px;

        @media (max-width: 768px) {
            font-size: 1.6rem;
        }
    }
}